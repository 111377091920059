import React, {useContext, useState} from "react"
import {Link} from "gatsby";
import CustomLink from "./link"
import LangContext from "../context/LangContext";

const Footer = () => {
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }

    return (
        <footer className="footer-consulting footer-dark bg-extra-dark-gray">
            <div className="footer-top pt-4 pb-5">
                <div className="container pl-5 ml-0 mb-5">
                    <div className="row f-logo">
                        <div className="col-12 text-left">
                            <Link to="/"><img src={"/images/latam_white.svg"} alt={''}
                                              data-at2x={"/images/latam_white.svg"}/></Link>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-lg-3 col-xl-2 col-sm-6 last-paragraph-no-margin md-margin-40px-bottom xs-margin-25px-bottom">
                            <span
                                className="alt-font font-weight-500 d-block margin-20px-bottom xs-margin-10px-bottom">{data["nosotros"]}</span>
                            <ul>
                                <li><CustomLink to="nosotros#acerca-de">{data["acerca_de_nosotros"]}</CustomLink></li>
                                <li><CustomLink to="nosotros#transacciones">{data["nuestra_trayectoria"]}</CustomLink>
                                </li>
                                <li><CustomLink to="nosotros#equipo">{data["nuestro_equipo"]}</CustomLink></li>
                                <li><CustomLink to="nosotros#reconocimientos">{data["reconocimientos"]}</CustomLink>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-lg-3 col-xl-2 col-sm-6 md-margin-40px-bottom xs-margin-25px-bottom">
                            <span
                                className="alt-font font-weight-500 d-block margin-20px-bottom xs-margin-10px-bottom">{data["servicios"]}</span>
                            <ul>
                                <li><CustomLink
                                    to="servicios#tab-servicios?activate=asesoria">{data["asesoria_financiera"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="servicios#tab-servicios?activate=estructuracion">{data["estructuracion"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="servicios#tab-servicios?activate=valuacion">{data["valuacion"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="servicios#tab-servicios?activate=situaciones">{data["reestructuras"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="servicios#tab-servicios?activate=gestion-tab">{data["gestion_de_fondos"]}</CustomLink>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-lg-3 col-xl-2 col-sm-6 xs-margin-25px-bottom">
                            <span
                                className="alt-font font-weight-500 d-block margin-20px-bottom xs-margin-10px-bottom">{data["sectores"]}</span>
                            <ul>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=transportacion">{data["transportacion"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=energia">{data["energia"]}</CustomLink></li>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=telecomunicaciones">{data["telecomunicaciones"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=infraestructura">{data["infraestructura_social"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=finanzas">{data["finanzas_publicas"]}</CustomLink>
                                </li>
                                <li><CustomLink
                                    to="sectores#tab-sectores?activate=sector">{data["sector_inmobiliario"]}</CustomLink>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-lg-3 col-xl-2 col-sm-6 last-paragraph-no-margin md-margin-40px-bottom xs-margin-25px-bottom">
                            <span
                                className="alt-font font-weight-500 d-block margin-20px-bottom xs-margin-10px-bottom">{data["inversionistas"]}</span>
                            <ul>
                                <li><a href="https://latamcapitaladvisors.citrixdata.com/" rel="noreferrer"
                                       target="_blank">{data["acceso_lps"]}</a></li>
                                <li><a href="https://latamcapitaladvisors-vdr.securevdr.com/" rel="noreferrer"
                                       target="_blank">{data["virtual_data_room"]}</a></li>
                            </ul>
                        </div>
                        <div
                            className="col-12 col-md-6 offset-md-3 offset-xl-0 mt-md-5 mt-xl-0 col-xl-4 col-sm-8 offset-sm-2 ">
                            <span
                                className="alt-font font-weight-500 d-block margin-20px-bottom xs-margin-10px-bottom">{data["suscribete_al_boletin"]}</span>
                            <p>{data["suscribete_text"]}</p>
                            <div className="newsletter-email position-relative w-100">
                                <form method="post" id="suscribir-form">
                                    <input className="large-input border-radius-4px m-0 border-0 " id="email"
                                           type="email" name="email" placeholder={data["correo"]}/>
                                    <input type="hidden" name="redirect" value=""/>
                                    <button
                                        className="btn btn-medium text-extra-dark-gray border-left border-0 border-color-extra-medium-gray font-weight-600 py-0 submit"
                                        type="submit">
                                        <i className="far fa-envelope icon-very-small margin-10px-right xs-no-margin-right"></i>
                                        <span className="d-none d-sm-inline-block">{data["suscribir"]}</span></button>
                                    <div
                                        className="form-results border-radius-4px position-absolute d-none"></div>
                                </form>
                            </div>
                            <p className="mt-4 mb-0">{data["email_de_contacto"]}: <a
                                href="mailto:info@latamcapitaladvisors.com">info@latamcapitaladvisors.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom pt-4 pb-4 border-top border-width-1px border-color-white-transparent">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3 mb-4 mb-lg-0 text-center text-md-left sm-margin-20px-bottom">
                            <Link to="/" className="footer-logo"><img src={"/images/latam_white.svg"} alt={''}
                                                                      data-at2x={"/images/latam_white.svg"}/></Link>
                        </div>
                        <div
                            className="col-12 col-md-6 col-lg-5 text-center last-paragraph-no-margin sm-margin-20px-bottom">
                            <p className="mb-2" style={{fontSize: '10px', lineHeight: '20px'}}>{data["copyright"]} <a href="https://www.kaplinski.ca"
                                                                     target="_blank">Kaplinski</a>.</p>
                            <p className={'m-0'} style={{fontSize: '10px', lineHeight: '15px'}}>{data["copyright_text1"]}</p>
                            <p className={'m-0'} style={{fontSize: '10px', lineHeight: '15px'}}>{data["copyright_text2"]}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 text-lg-right">
                            <div id="page"
                                 className="d-flex justify-content-between text-center flex-wrap flex-md-nowrap">
                                <CustomLink to="mapa-sitio">{data["mapa_del_sitio"]}</CustomLink>
                                <CustomLink to="politica-privacidad">{data["politica_de_privacidad"]}</CustomLink>
                                <CustomLink to="terminos-condiciones">{data["terminos_de_uso"]}</CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


export default Footer
